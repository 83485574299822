// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAmZCIUPR9gjpyd_qhK81UK27TwgHao_5M",
    authDomain: "farmacia-7c15c.firebaseapp.com",
    projectId: "farmacia-7c15c",
    storageBucket: "farmacia-7c15c.appspot.com",
    messagingSenderId: "546339300080",
    appId: "1:546339300080:web:36c827136aa16c82659835",
    measurementId: "G-NYNE4F8SSL"
  };
  export default firebaseConfig;